import React from 'react'
import Button from '../theming/Button'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import H2 from '../theming/H2'

export default ({
  title = <FormattedMessage id="cta.we-search-for-you" />,
}) => {
  return (
    <section className="c-row c-row--gamma c-row--centered">
      <div className="o-container">
        <div className="o-grid">
          <div className="o-grid__item u-4-of-6-bp3 u-push-1-of-6-bp3 u-2-of-6-bp5 u-push-2-of-6-bp5">
            <H2 className="title">{title}</H2>
            <p>
              <FormattedHTMLMessage id="cta.tell-us-what-you-are-looking-for" />{' '}
            </p>
            <Button
              to="/personal-updates"
              kind="alpha"
              className="c-button--md c-button--block"
            >
              <FormattedMessage id="page.get-personal-updates" />
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
